<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
         

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Select Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
            <template #selected-option="{ site_name }">
                <span v-if="site_name && site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name && site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="suppliers"
              label="name"
              class="w-100"
              placeholder="Supplier"
              @input="filterTable()"
              v-model="supplierData"
              :clearable="false"
            >
            <template #selected-option="{ name }">
                <span v-if="name.length < 23">{{name}}</span>
                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <b-col 
           cols="12"
           md="4"
           class="mb-md-0 mobile_tab_max_width_flex"
          >

            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">

              <b-button
                
                variant="danger"
                class="mobile-margin-bottom mr-1"
                @click="setParamsValue()"
              >
                <span class="text-nowrap">Reset</span>
              </b-button>

              <b-button
                :disabled="selected.length == 0"
                variant="primary"
                class="mobile-margin-bottom mr-1"
                @click="unlinkItem()"
              >
                <span class="text-nowrap">Unlink Item</span>
              </b-button>

              
            </div>
          </b-col>
          
        </b-row>
      </b-card-body>
    </b-card>

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />
              
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #head(checkbox)="items">
            <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall" />
        </template>

        <template #cell(checkbox)="items">
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" />
        </template>

        <template #cell(info)="items">             
            <feather-icon
                :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                @click="items.toggleDetails"
            />
        </template>

        <template #cell(name)="items">
        
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.name | capitalize }}</span>
    
        </template>

        <template #cell(supplier_name)="items">
        
                <span class="wordBreak" @click="items.toggleDetails"> {{ items.item.supplier.name | capitalize }}</span>
    
        </template>

    
        <template #cell(status)="items">
        
            <b-badge
                pill
                :variant="variantColor(items.item.status)"
                class="text-capitalize"
                @click="items.toggleDetails"
            >
                {{ items.item.status }}
            </b-badge>
        
        </template>

        <template #cell(updated_at)="items">
        
            <span @click="items.toggleDetails">{{ sitedateTime(items.item.updated_at, 'DD MMM YYYY', 'HH:mm') }}</span>
        
        </template>

        <template #row-details="items">
                <div class="p-1 px-2 fbDetail mb-1">
                    <b-row>

                      <b-col cols="12" xl="12" >
                          <table class="mt-2 mt-xl-0 w-100">
                              <tr class="mb-1">
                                  <th class="pb-50" style="width: 16%">
                                    <div class="d-flex align-items-center">
                            
                                      <div class="ml-1">
                                        <h6 class="mb-0 mediumFonts">
                                          Internal Item Code 
                                        </h6>
                                      <!--  <small>Monthly Sales</small> -->
                                      </div>
                                    </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    {{ items.item.code | capitalize }}
                                  </td>

                              

                                  <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Category 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    {{ items.item.category.name | capitalize }}
                                  </td>

                                  <th class="pb-50" style="width: 16%">
                                    <div class="d-flex align-items-center">
                              
                                      <div class="ml-1">
                                        <h6 class="mb-0 mediumFonts">
                                          Sub Category 
                                        </h6>
                                      <!--  <small>Monthly Sales</small> -->
                                      </div>
                                    </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span v-if="items.item.sub_category != null">{{ items.item.sub_category.name | capitalize }} </span>
                                      <span v-else>{{ 'N/A' }}</span>
                                  </td>
                                </tr>



                                <tr class="mb-1">

                                  <th class="pb-50" style="width: 16%">
                                    <div class="d-flex align-items-center">
                              
                                      <div class="ml-1">
                                        <h6 class="mb-0 mediumFonts">
                                          Packing 
                                        </h6>
                                      <!--  <small>Monthly Sales</small> -->
                                      </div>
                                    </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        {{ items.item.packing }}
                                  </td>


                                  <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                              
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Rate / Unit Price 
                                          </h6>
                                        <!--  <small>Monthly Sales</small> -->
                                        </div>
                                      </div>
                                  </th>
                                  <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span class="wordBreak">{{ '$ ' + items.item.unit_price }}</span>
                                  </td>

                                  

                              
                              </tr>

                                <tr class="mb-1">
                                <th class="pb-50" style="width: 16%">
                                  <b-button
                                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                      variant="outline-secondary"
                                      @click="items.toggleDetails"
                                      size="sm"
                                      class="ml-1"
                                    >
                                      Hide Detail
                                    </b-button>
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                                <th class="pb-50" style="width: 16%">
                                </th>
                                <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                    
                                </td>
                          </tr>
                          </table>
                      </b-col>

                    </b-row>
                </div>

            </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {

        tableColumns: [
          
            { key: 'checkbox', label: '', sortable: false, thStyle: { width: '5%' } },
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '10%' } },
            { key: 'name', label: 'Item Name', sortable: true, thStyle: { width: '25%' } },
            { key: 'supplier_name', label: 'Supplier Name', sortable: false, thStyle: { width: '20%' } },
            { key: 'status', label: 'Status', sortable: true, thStyle: { width: '20%' } },
            { key: 'updated_at', label: 'Added On', sortable: true, thStyle: { width: '20%' } },
            
        ],
        
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'updated_at',
       
       
        sites:[],
        siteData:null,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:0,
        to:0,
        selectAll: false,
        selected: [],
        suppliers:[],
        supplierData:null,
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Inventory'
      },{
        to:null,
        text: 'Settings'
      },{
        to:{name:'project-site-items'},
        text: 'Project Site Items',
      },{
        to:null,
        text: 'View Items',
        active:true
      }];
      return item;
    },
    
    dataList() {
            return this.$store.dispatch(POST_API, {
                data: {
                    page: this.currentPage,
                    keyword: this.searchQuery,
                    rowsPerPage: this.perPage,
                    sortBy: this.sortBy,
                    sortDirection: this.isSortDirDesc,
                    role: this.$store.getters.currentUser.role,
                    om_sites: this.$store.getters.currentUser.om_sites,

                    supplier: this.supplierData ? this.supplierData._id : null,
                    category: null,
                    sub_category: null,
                    site: this.siteData ? this.siteData._id : null,
                },
                api: '/api/suppliers-items-list'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.selected = [];
                        this.selectAll = false;
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
        },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    
    allInventorySites() {
        return this.$store.dispatch(POST_API, {
            data: {
                role: this.$store.getters.currentUser.role,
            },
            api: '/api/all-inventory-sites'
        })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.sites = this.$store.getters.getResults.data;

                    return this.sites;
                }
            });
    },
    getSuppliers() {
            return this.$store.dispatch(POST_API, {
                data: {
                    role: this.$store.getters.currentUser.role,
                },
                api: '/api/all-suppliers'
            })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0, 0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;

                        this.suppliers = data;
                        return this.suppliers;
                    }
                });
        },
    async setParamsValue(){
      await this.allInventorySites();
      console.log(this.$route.params.site_id);
      if (this.$route.params.site_id != '') {
        var siteData = this.sites.filter(item => {return item._id == this.$route.params.site_id});  
        
        this.siteData = siteData.length > 0 ? siteData[0] : {_id: this.$route.params.site_id,name:'Site' } ;  
      }

        this.supplierData = [];  
      
      this.filterTable();

    },
    selectall(e) {

      this.selected = [];

      if (e) {
        var selected = [];

        this.items.forEach(function (item) {
          selected.push(item._id);
        })

        this.selected = selected;

      }
    },
    unlinkItem(){
      return this.$store.dispatch(POST_API, {
        data: {

          ids: this.selected.reverse(),
          site: this.siteData._id
        },
        api: '/api/unlink-site-to-items'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.popshowDismissibleAlert = true;
        } else {
          this.popshowDismissibleAlert = false;
          var message = this.$store.getters.getResults.message;
          this.successAlert()
            .then((result) => {
              this.filterTable();
            });
        }
      });
    }
  },
  mounted(){
    this.setParamsValue();
    this.getSuppliers();
  }
  
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
